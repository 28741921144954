<template>
  <!-- <div> -->
    <DataEntry
      :reuseComponent="reuseComponent"
      :downloadColumns="downloadColumns"
    ></DataEntry>
  <!-- </div> -->
</template>
<script>
import DataEntry from "../../../transit/_components/School/dataEntry.vue";
export default {
  components: { DataEntry },
  data: () => ({
    reuseComponent: true,
    downloadColumns: [
      {
        value: "download_excel_raw",
        name: "Download Excel File (RAW)",
        selected: false,
      },
      { value: "upload_excel", name: "Upload Excel", selected: false },
    ],
  }),
};
</script>